import React from "react";
import withSizes from 'react-sizes';
import { TweenLite } from "gsap/all";

import ThumbItem from './thumbItem';

// Set window size
const getWindowSize = ({ width, height }) => ({
	win: {
		width: width,
		height: height
	}
});

class Thumbs extends React.Component {
	
	constructor(props) {
		super(props);
		this.allThumbs = [];
	}

	componentDidMount() {
		// console.log(this.allThumbs);
	}

	// shouldComponentUpdate( nextProps ) {
	// 	console.log(this.props.activeImage);
	// 	console.log(nextProps.activeImage);

	// 	if ( this.props.activeImage === nextProps.activeImage ) {
	// 		return false;
	// 	} else {
	// 		return true;
	// 	}
	// }

	componentDidUpdate() {
		// Listen until all the thumb animations are complete
		// const updateViewport = this.props.updateViewport;
		// const animPromises = [];
		// this.allThumbs.forEach(( component ) => {
		// 	const tween = TweenLite.getTweensOf( component.theThumb );
		// 	let promise;
		// 	if ( tween.length ) {
		// 		promise = new Promise(function( resolve ) {
		// 			tween[0].vars.onComplete = resolve;
		// 		});
		// 	} else {
		// 		promise = new Promise(function( resolve ) {
		// 			resolve();
		// 		});	
		// 	}
		// 	animPromises.push( promise );
		// });
		
		// Promise.all( animPromises ).then(function() {
		// 	console.log('ALL ANIMATIONS COMPLETE');
		// 	// updateViewport();
		// });
	}

	render() {	
		const thumbs = this.props.images.edges;
		const onThumbClick = this.props.onThumbClick;
		return (
			<section className="thumb-container">
				{[...thumbs].reverse().map(({ node }, index ) =>
						<ThumbItem
							key={ node.id }
							ref={div => this.allThumbs[index] = div}
							node={ node }
							win={ this.props.win }
							onThumbClick={() => {
								onThumbClick( node );
							}}
						/>
				)}
			</section>

		)
	}
}


export default withSizes( getWindowSize )( Thumbs );