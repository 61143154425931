import React from "react";
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Transition } from "react-transition-group";
import { TweenLite, Power2 } from "gsap";

import Utils from '../utils/utils';

import logoImg from '../images/large-logo.svg';

// ANIMATION FUNCTION
let viewportTween;

const toggleViewport = ( viewportEl, animState, activeImage ) => {
	const scale = animState === 'show' ? 1 : 0;
	// const skew = animState === 'show' ? 0 : '15deg';
	let duration = animState === 'show' ? 100 : 200;
	if ( activeImage === 'beginning' ) {
		duration = 0;
	}
	console.log('Animate : '+animState);
	
	// console.log(viewportEl);
	
	viewportTween = TweenLite.to( viewportEl, duration / 1000, {
		// scaleX: scale,
		// skewX: skew,
		opacity: scale,
		// skewType: "simple",
		ease: animState === 'show' ? Power2.easeIn : Power2.easeOut,
		// onComplete: function () {
			
		// }
	}).paused( true );
};

class Viewport extends React.Component {
	
	constructor(props) {
		super(props);
		this.viewportImage = React.createRef();
		this.state = {
			visible: true,
			activeImage: this.props.activeImage,
			imageLoaded: true,
			animating: false
		};
	}

	getSrcs = ( prevProps, prevState, nextProps, nextState ) => {
		const srcs = {
			propsSrc: this.props.activeImage.image ? this.props.activeImage.name : this.props.activeImage,
			stateSrc: this.state.activeImage.image ? this.state.activeImage.name : this.props.activeImage
		};
		if ( prevProps ) {
			srcs.prevPropsSrc = prevProps.activeImage.image ? prevProps.activeImage.name : this.props.activeImage
		}
		if ( prevState ) {
			srcs.prevStateSrc = prevState.activeImage.image ? prevState.activeImage.name : this.props.activeImage
		}
		if (nextProps) {
			srcs.nextPropsSrc = nextProps.activeImage.image ? nextProps.activeImage.name : this.props.activeImage
		}
		if (nextState) {
			srcs.nextStateSrc = nextState.activeImage.image ? nextState.activeImage.name : this.props.activeImage
		}
		return srcs;
	}

	componentDidMount () {
		console.log('COMPONTENT MOUNTED');
		// PRELOAD IMAGES (if not mobile)
		if ( !this.props.mobile ) {
			this.props.images.edges.forEach(({node}) => {
				const srcOptions = node.image.childImageSharp;
				const src = this.getImageSrc( srcOptions );
				Utils.preloadImage( src );
			});
		}
		
	}

	componentDidUpdate( prevProps, prevState ) {

		const srcs = this.getSrcs( prevProps, prevState );
		console.log(srcs);
		console.log('loaded: ' + this.state.imageLoaded );
		console.log('visible: ' + this.state.visible );
		console.log('animating: ' + this.state.animating );
		
		// Hide
		if (
			this.state.visible &&
			!this.state.animating &&
			srcs.propsSrc !== srcs.stateSrc
			// && srcs.propsSrc !== srcs.prevPropsSrc
		) {
			console.log('HIDE');
			this.setState({
				visible: false,
				animating: true
			});
			return;
		}

		// Update src while hidden
		if (
			!this.state.animating &&
			srcs.propsSrc !== srcs.stateSrc
			// && srcs.propsSrc !== srcs.prevPropsSrc
		) {
			console.log('Update src while hidden');
			const loaded = this.testImageNeedsLoading(this.props.activeImage);
			this.setState({
				activeImage: this.props.activeImage,
				imageLoaded: loaded
			});
			return;
		}
	}
	
	// CALLED BEFORE ENTERING OCCURS
	handleEnter = () => {
		console.log("handleEnter");
		// START THE ANIMATION with a delay
		const delay = 100;
		toggleViewport(this.viewportImage, 'show');
		setTimeout(() => {
			this.setState({ animating: true });
			viewportTween.paused( false );
		}, delay);
	}

	// ON END OF ANIMATION IN
	handleEntered = () => {
		console.log('End of animating in');
		this.setState({ animating: false });
	}

	// RUN ANIMATION OUT
	handleExit = () => {
		console.log("handleExit");
		toggleViewport(this.viewportImage, 'hide', this.state.activeImage);
		viewportTween.paused(false);
	}
	

	// ON END OF ANIMATION OUT
	handleExited = () => {
		this.setState({ animating: false });
		console.log("********** handleExited and SET NEW IMAGE SRC");
		console.log(this.props.activeImage);
		const loaded = this.testImageNeedsLoading(this.props.activeImage);
		// Set new image src
		this.setState({
			activeImage: this.props.activeImage,
			imageLoaded: loaded,
			visible: loaded
		});

	}

	handleImageLoad = () => {
		console.log('handleImageLoad');
		console.log(this.state.activeImage.image.childImageSharp.long);
		this.setState({ imageLoaded: true, visible: true });
	}
	
	testImageNeedsLoading = ( propsImage ) => {
		console.log('()()()() testImageNeedsLoading');		
		
		// No loading because no image
		if (propsImage === 'beginning' || propsImage === 'end' ) {
			console.log('image loaded');
			return true;
		}
		// No loading because same image
		else if ( propsImage.name === this.state.activeImage.name ) {
			console.log('image already loaded');
			return true;
		}
		// Requires loading
		else {
			console.log('image not loaded');
			return false;
		}
	}

	// Get image src dependent on aspect ratio
	getImageSrc = ( imageOptions ) => {
		const ratio = imageOptions.long.aspectRatio;
		if ( ratio > 1 ) {
			return imageOptions.long.src;
		} else {
			return imageOptions.tall.src;
		}
	}

	render() {
		let activeImage = this.state.activeImage;
		let hasImage = false;
		let imageClassname = 'viewport-image';
		let src;
		let alt;
		let intro = false;
		const styles = {
			height: 'auto'
		};
		// Handle images
		if ( activeImage !== 'beginning' && activeImage !== 'end' ) {
			hasImage = true;
			// Set extra images styles
			if ( activeImage.y  ) {
				imageClassname += ' positioned';
				if (activeImage.y === 'bottom' ) {
					styles.bottom = 0;
				}
				if (activeImage.y === 'top') {
					styles.bottom = 'auto';
					styles.top = 0;
				}
				if (activeImage.height) {
					styles.height = activeImage.height;
				}
			}
			// Get alt name
			alt = activeImage.name;
			// Get image src
			activeImage = activeImage.image;
			src = this.getImageSrc( activeImage.childImageSharp );
			
		} else {
			// Handle text slides
			if ( activeImage === 'beginning' ) {
				intro = true;
			}
		}

		return (
			<>
				{this.props.mobile ? (
					<MobileViewport images={this.props.images} />
				) : (
					<Transition
						in={this.state.visible}
						onEnter={this.handleEnter}
						onEntered={this.handleEntered}
						onExit={this.handleExit}
						onExited={this.handleExited}
						mountOnEnter={true}
						appear={true}
						addEndListener={( node, done ) => {
							viewportTween.eventCallback('onComplete', function () {
								console.log('DONE ANIMATING');
								done();
							});
						}}
					>
						<section className="viewport">
							
							<ViewportNav slide={activeImage} onClick={this.props.onNavClick} />
							
							<ViewportLogo />
							<ViewportAnimationLink />
								
							<div className="viewport-slide" ref={div => this.viewportImage = div}>
								{hasImage ? (
									<img	
										className={imageClassname}
										src={src}
										alt={alt}
										onLoad={this.handleImageLoad}
										style={styles}
									/>
								) : (
									<>
									{intro ? (
										<ViewportIntro mobile={false} />
									) : (
										<ViewportOutro />
									)}
									</>
								)}
							</div>
						</section>
					</Transition>
				)}
			</>
		);
	}
}

const ViewportNav = function ( {slide, onClick} ) {

	let containerClass = 'viewport-nav';
	if ( slide === 'beginning' ) {
		containerClass += ' initial';
	}
	if (slide === 'end') {
		containerClass += ' outro';
	}
	
	return (
		<div className={containerClass}>
			<a role="button" className="viewport-nav-button prev" onClick={()=>onClick('prev')}></a>
			<a role="button" className="viewport-nav-button next" onClick={()=>onClick('next')}></a>
		</div>
	);
};

const ViewportIntro = function ( { mobile } ) {
	const style = {
		opacity: mobile ? 1 : 0
	};

	return (
		<div className="viewport-content viewport-intro" style={style}>
			<h1 className="intro-title">collages</h1>
			<h2 className="intro-subtitle">
				<a href="https://elwyn.co" target="_blank" rel="noopener noreferrer">elwyn.co</a>
			</h2>
			<ViewportAnimationLink />
		</div>
	);
};

const ViewportOutro = function () {

	return (
		<div className="viewport-content viewport-outro">
			<p className="intro-title">end</p>
		</div>
	);
};

const MobileViewport = function ( props ) {
	console.log(props.images);
	const slideStyle = {
		opacity: 1,
		position: 'relative'
	};
	const images = props.images.edges;
	return (
		<div className="mobile-viewport">
			<div className="viewport-slide" style={slideStyle}>
				<ViewportIntro mobile={true} />
			</div>
			{images.map(({ node }) =>
				<figure className="mobile-image-container" key={node.id}>
					<Img fluid={node.image.childImageSharp.fluid} 
						imgStyle={{
							width: 'auto',
							height: 'auto',
							position: 'static'
						}}
						placeholderStyle={{
							display: 'none'
						}}
					/>
				</figure>
			)}
		</div>
	);
};

function ViewportLogo () {
	return (
		<div className="logo-container">
				<a href="https://elwyn.co">
					<img className="logo" src={logoImg} alt="Elwyn.co"/>
				</a>
		</div>
	);
}

function ViewportAnimationLink({ mobile }) {
	return (
		<div className="animation-link">
			<a target='_blank' rel='noopener noreferrer' href="https://youtu.be/VzUgF7cdGTc">
				<strong>NEW</strong> Phone Battery Dead
			</a>
		</div>
	);
}

export default Viewport;

Viewport.propTypes = {
	images: PropTypes.object,
	activeImage: PropTypes.string,
	mobile: PropTypes.bool,
	onNavClick: PropTypes.func,
};

ViewportIntro.propTypes = {
	mobile: PropTypes.bool,
};

ViewportAnimationLink.propTypes = {
	mobile: PropTypes.bool,
};

ViewportNav.propTypes = {
	slide: PropTypes.string,
	onClick: PropTypes.func,
};

MobileViewport.propTypes = {
	images: PropTypes.object,
};