import React from 'react'
import { graphql } from 'gatsby'
import { cloneDeep } from 'lodash';

import Layout from '../globals/layout'
import SEO from '../globals/seo'

import Thumbs from '../components/thumbs'
import Viewport from '../components/viewport'

import ThumbFunctions from '../utils/ThumbFunctions'
import Utils from '../utils/utils'

import Mousetrap from 'mousetrap'

let device;

class IndexPage extends React.Component {
  
  constructor(props) {
    super(props);
    let data = this.props.data;
    // Add indices
    data = addIndices( data );
    // Add thumb positions
    data = addThumbPosition( data );
    // Add thumb margins
    addRandomMargins( data );
    // Set initial state
    this.state = {
      images: data.allImagesYaml,
      activeImage: 'beginning',
      currentSlide: 0,
      mobile: null
    };
  }

  componentDidMount() {
    // Load device package
    device = require('current-device').default;
    this.setState({ mobile: device.mobile() });
    // KEYBOARD CONTROLS
    Mousetrap.bind('up', () => {
      this.progressImages( null, 'prev' );
    });
    Mousetrap.bind('down', () => {
      this.progressImages( null, 'next' );
    });
  }

  // Remove listeners on unmount
  componentWillUnmount() {
    Mousetrap.unbind('up');
    Mousetrap.unbind('down');
  }
  
  // ON THUMB CLICK
  onThumbClick = ( clickedNode ) => {
    console.log(clickedNode);
    console.log(this.state.images.edges);
    // Progress thumbs and slideshow
    this.progressImages( clickedNode );
  };

  // ON VIEWPORT CLICK
  onViewportClick = ( direction ) => {
    // Progress thumbs and slideshow
    console.log(direction);
    this.progressImages(null, direction);
  };

  getActiveImageNode = ( index = this.state.currentSlide ) => {
    const slideIndex = index;  
    const images = this.state.images.edges;
    const totalImages = images.length;
    const activeImageNode =
      slideIndex === 0 ? 'beginning' :
      slideIndex > totalImages ? 'end' :
      images[ slideIndex - 1 ].node;
    return activeImageNode;
  }

  // PROGRESS IMAGES
  progressImages = ( clickedNode, direction ) => {
    let slideIndex = this.state.currentSlide;
    let totalSlides = this.state.images.totalCount;
    const images = cloneDeep( this.state.images );
    
    // Stop if at start and going back
    if ( slideIndex === 0 && direction === 'prev' ) {
      console.log('Stop at start');
      this.setState(state => ({
        activeImage: this.getActiveImageNode( slideIndex ),
      }));
      return;
    }
    // Stop if at end and going forward
    if ( slideIndex === (totalSlides + 1) && direction === 'next' ) {
      console.log('Stop at end');
      return;
    }
    // Get clicked node if none defined
    const thumbsState =
      slideIndex === totalSlides ? 'noTop' :
      slideIndex === 1 ? 'noBottom' :
      null;

    clickedNode = clickedNode || ThumbFunctions.getFirstImageNode( images, direction, thumbsState );
    // Get clicked node position
    let thumbPosition = clickedNode.position;
    // Get direction from position of clicked thumb if no directoin defined
    direction = direction || ThumbFunctions.getDirection(thumbPosition, thumbsState );
    // Get clicked image row from thumb index
    let clickedRow = clickedNode.index;
    let firstRow = images.edges[ clickedRow ];
    // Get second thumb row
    let secondNode = ThumbFunctions.getSecondImageNode( images, thumbPosition );
    // Set new position on thumbs
    let newClickedThumbPos = ThumbFunctions.getThumbNewPosition( thumbPosition, direction );
    firstRow.node.position = newClickedThumbPos;
    if ( secondNode ) {
      let newSecondThumbPos = ThumbFunctions.getThumbNewPosition( secondNode.position, direction );
      secondNode.position = newSecondThumbPos;
    }

    if ( direction === 'next' ) {
      slideIndex++;
    } else {
      slideIndex--;
    }

    // Define the active image
    const nextImageNode = this.getActiveImageNode(slideIndex);
    const activeImageNode =
      thumbPosition === 'middle' ? nextImageNode :
      clickedNode;
    // console.log('*******************');
    // console.log('thumb position: ' + thumbPosition);
    // console.log('clicked:');
    // console.log(clickedNode);
    // console.log('next:');
    // console.log(nextImageNode);
    // console.log('active:');
    // console.log(activeImageNode);
    // console.log('*******************');
    
    
    this.setState(state => ({
      images: images,
      activeImage: activeImageNode,
      currentSlide: slideIndex
    }));

  };

  render() {
    return(
      <Layout>
        <SEO title="" />
        {!this.state.mobile &&
          <Thumbs
            images={ this.state.images }
            activeImage={ this.state.activeImage }
            onThumbClick={ this.onThumbClick }
            updateViewport={ this.updateViewport }
          />
        }
        <Viewport
          images={ this.state.images }
          activeImage={ this.state.activeImage }
          onNavClick={ this.onViewportClick }
          mobile={ this.state.mobile }
        />
      </Layout>
    );
  }
};

export default IndexPage

export const thumbsQuery = graphql`
  query {
    allImagesYaml {
      totalCount
      edges {
        node {
          name
          y
          height
          id
          thumb {
            childImageSharp {
              resize(width: 160) {
                src
                aspectRatio
              }
            }
          }
          image {
            childImageSharp {
              long:resize(width: 1400) {
                src
                aspectRatio
              }
              tall:resize(height: 1000) {
                src
                aspectRatio
              }
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;


// DATA UTILS
// ----------------------
function addThumbPosition ( data ) {
  data.allImagesYaml.edges.forEach(function ( obj ) {
    obj.node.position = 'top';
  });

  return data;
}

function addIndices ( data ) {  
  data.allImagesYaml.edges.forEach(function ( obj, index ) {
    obj.node.index = index;
  });

  return data;
}

function addRandomMargins( data ) {  
  data.allImagesYaml.edges.forEach(function (obj, index) {
    obj.node.marginLeft = Utils.getRandomNumber(-20,20);
    obj.node.marginTop = Utils.getRandomNumber(-20,20);
  });

  return data;
}