import React from "react";
import Img from 'gatsby-image';
import { TweenLite } from "gsap";

const thumbPos = 30;

const moveThumb = ( node, thumb, win, animate = true ) => {
		const pos = node.position;
		const marginLeft = node.marginLeft;
		const marginTop = node.marginTop;
		const thumbHeight = thumb.offsetHeight;
		
		const y = 
			pos === 'top' ? 0: // top
			pos === 'middle' ? (win.height / 2) - (thumbHeight / 2) - thumbPos : // middle
			win.height - thumbHeight - (thumbPos * 1.5); // bottom

		const x = pos === 'middle' ? -marginLeft : 0;

		const duration = 0.2;

		if ( animate ) {
			TweenLite.to( thumb, duration, {
				y: y,
				x: x
			});
		} else {
			TweenLite.set( thumb, {
				y: y,
				x: x
			});
		}
};

class ThumbItem extends React.Component {

	constructor(props) {
		super(props);
		this.theThumb = null;
	}

	componentDidMount () {
		// Set thumb position
		moveThumb( this.props.node, this.theThumb, this.props.win, false );
	}

	// Test if anything needs updating
	shouldComponentUpdate( nextProps ) {
		const currentPos = this.props.node.position;
		const nextPos = nextProps.node.position;
		const winHeight = this.props.win.height;
		const newWinHeight = nextProps.win.height;
		if ( currentPos === nextPos && winHeight === newWinHeight ) {
			return false;
		} else {
			return true;
		}
	}

	componentDidUpdate( prevProps ) {
		const node = this.props.node;
		const pos = node.position;
		let animate = true;
		
		// Only set position if position props haven't changed
		if ( pos === prevProps.node.position ) {
			animate = false;
		}

		moveThumb( node, this.theThumb, this.props.win, animate );
	}

	render() {
		const onThumbClick = this.props.onThumbClick;
		const node = this.props.node;
		const pos = node.position;
		const src = node.thumb.childImageSharp.resize.src;
		const alt = node.name;
		
		// Set margins
		const imgStyle = {
			marginLeft: node.marginLeft + 'px',
			marginTop: node.marginTop + 'px'
		}

		
		
		let classNames = ['thumb'];
		if ( pos === 'middle' ) {
			classNames.push('middle');
		}
		classNames = classNames.join(' ');
		
		return (			
			<a
				role="button"
				className={classNames}
				ref={a => this.theThumb = a}
				onClick={onThumbClick}
				style={{
					top: `${thumbPos}px`
				}}
			>
				<img src={src} style={imgStyle} alt={alt} />
			</a>
		);
	}
}

export default ThumbItem;